import"../styles/main.scss";

// import jQuery from "jquery/dist/jquery.slim.min.js";
// const $ = jQuery;

import gsap from"gsap";
import{ ScrollTrigger }from"gsap/ScrollTrigger";
import{ ScrollToPlugin }from"gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export const mm = gsap.matchMedia();

window.addEventListener("DOMContentLoaded", () => {
    import("./loader").then((init) => init.default());
});

gsap.config({
    nullTargetWarn: false,
    force3D: true,
    autoSleep: 60
});
